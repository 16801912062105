import request from "request-promise-native";
import notification from "antd/lib/notification";
import queryString from "query-string";
import { forceLogout } from "../utils/tools";

export const PROTOCOL = window.location.protocol;
export const TOP_DOMAIN = window.location.host
  .split(".")
  .slice(-2)
  .join(".");
const SUB_DOMAIN = "pro";
const API_ENDPOINT = `${window.location.protocol}//${SUB_DOMAIN}.${TOP_DOMAIN}/api`;
export const WWWHost = window.location.host.replace("pro", "www");

const smValidate = (url, cb) => {
  const whiteList = [
    "member_only_contents/member_info.json",
    "/consultations/product.json",
    "/settings/mobile/verified",
    "/login",
    "/logout",
    "/signup",
    "/mobile_verify_code",
    "/coupon_codes/check.json?code=",
  ];
  if (
    window.currentUser &&
    !window.currentUser.mobile_verified &&
    !whiteList.some(item => url.indexOf(item) > 0)
  ) {
    forceLogout();
  } else {
    return cb();
  }
};

const getRequestDealWithAuth = url =>
  smValidate(url, () =>
    request(url, {
      headers: {
        "content-type": "application/json",
      },
    }).catch(err => {
      if (err.statusCode != 422 && err.statusCode != 403) {
        return;
      }

      notification.error({
        message: "无权限访问",
        description: "请您重新登录",
      });

      window.setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    })
  );

const formPost = (uri, data, callback, method = "POST") =>
  smValidate(uri, () => {
    let csrfToken = document.querySelector("meta[name=csrf-token]").content;

    return request(
      {
        method: method,
        uri: uri,
        form: data,
        headers: {
          "X-CSRF-Token": csrfToken,
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
        },
        withCredentials: true,
        json: true,
      },
      callback
    );
  });

const requestWrap = (uri, params, method = "POST") =>
  smValidate(uri, () => {
    let csrfToken = document.querySelector("meta[name=csrf-token]").content;

    return request({
      method: method,
      uri: uri,
      body: params,
      headers: {
        "X-CSRF-Token": csrfToken,
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
      withCredentials: true,
      json: true,
    });
  });

export const fetchDatabaseDetail = id => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/search/${id}.json`).then(
    JSON.parse
  );
};

export const fetchAssetsData = (id, params = {}) => {
  let queryParams = queryString.stringify(params, {
    arrayFormat: "bracket",
  });

  return getRequestDealWithAuth(
    `${API_ENDPOINT}/search/${id}/investments?${queryParams}`
  ).then(JSON.parse);
};

export const fetchKeywordResultData = (type, keyword) => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/data/${type}s/recommend.json?keyword=${keyword}`
  ).then(JSON.parse);
};

export const fetchFiltersData = type => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/data/${type}s/filter.json`
  ).then(JSON.parse);
};

export const fetchDatabaseListData = (type, params) => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/data/${type}s.json?${params}`
  ).then(JSON.parse);
};

export const fetchResearchFilters = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/researches/filter.json`).then(
    JSON.parse
  );
};

export const fetchResearchList = params => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/researches.json${params ? `?${params}` : ""}`
  ).then(JSON.parse);
};

export const fetchResearchDetail = id => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/researches/${id}.json`).then(
    JSON.parse
  );
};

export const infoInResearchDetail = data => {
  return requestWrap(`${API_ENDPOINT}/researches/${data.id}/obtain.json`, data);
};

export const fetchNewsList = (category, params? = "") => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/trend/dynamics.json?category=${category}&${params}`
  ).then(JSON.parse);
};

export const fetchCategoriesInNews = () => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/trend/dynamics/categories.json `
  ).then(JSON.parse);
};

export const fetchLatestInsight = () => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/trend/articles/banners.json`
  ).then(JSON.parse);
};

export const fetchInsightList = (params, urlParmas? = "") => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/trend/articles.json?${
      params ? `${params}` : ""
    }${urlParmas}`
  ).then(JSON.parse);
};

export const fetchInsightCategories = () => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/trend/articles/categories.json`
  ).then(JSON.parse);
};

export const fetchCommunicationList = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/communications.json`).then(
    JSON.parse
  );
};

export const fetchSubscribeList = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/columns.json`).then(
    JSON.parse
  );
};

export const fetchSubscribeDetail = id => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/columns/${id}.json`).then(
    JSON.parse
  );
};

export const userSubscribe = (id, callback) => {
  return formPost(
    `${API_ENDPOINT}/columns/${id}/subscribe.json`,
    {},
    (response, body) => callback(body)
  );
};

export const login = (data, callback) => {
  formPost(
    `${window.location.protocol}//${SUB_DOMAIN}.${TOP_DOMAIN}/login`,
    data,
    (response, body) => callback(body)
  );
};

export const logout = callback => {
  return formPost(
    // TODO: Need backend change api url consistent with pro other API
    `${PROTOCOL}//${WWWHost}/logout`,
    {},
    (response, body) => callback(body),
    "GET"
  );
};

export const register = (data, callback) => {
  formPost(
    `${window.location.protocol}//${SUB_DOMAIN}.${TOP_DOMAIN}/signup`,
    data,
    (response, body) => callback(body)
  );
};

export const identify = data => {
  return requestWrap(`${API_ENDPOINT}/certifications.json`, data);
};

export const getIdentificationInfo = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/certifications.json`);
};

export const contact = data => {
  return requestWrap(`${API_ENDPOINT}/consultations/membership.json`, data);
};

export const fetchProductsInfo = couponCode => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/consultations/product.json?code=${couponCode}`
  ).then(JSON.parse);
};

export const orderProduct = id => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/users/order.json?productId=${id}`
  ).then(JSON.parse);
};

export const fetchPurchaseQrcode = (id, couponCode) => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/users/get_wechat_qr.json?orderId=${id}&code=${couponCode}`
  ).then(JSON.parse);
};

export const fetchPurchaseStatus = id => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/users/order_poll.json?orderId=${id}`
  ).then(JSON.parse);
};

export const fetchAlphaInfo = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/users/alpha_rights.json`).then(
    JSON.parse
  );
};

export const fetchMembership = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/users/pro_receive.json`).then(
    JSON.parse
  );
};

export const onTrialStart = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/users/trial.json`).then(
    JSON.parse
  );
};

export const fetchPersonalInfo = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/users/baseinfo.json`).then(
    JSON.parse
  );
};

export const fetchCertificationInfo = () => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/users/certification.json`
  ).then(JSON.parse);
};

export const fetchVipInfo = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/users/memberinfo.json`).then(
    JSON.parse
  );
};

export const specialCheckCode = () => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/coupon_codes/special_check.json`
  ).then(JSON.parse);
};

export const updateName = (name, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/settings/profile`,
    {
      "user[name]": name,
    },
    (response, body) => callback(body),
    "PATCH"
  );
};

export const activateEmail = callback => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/settings/verify_email`,
    {},
    (response, body) => callback(body)
  );
};

export const fetchPhoneVerifyCode = (data, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/mobile_verify_code`,
    data,
    (response, body) => callback(body)
  );
};

export const fetchNewCodeVerify = (data, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/mobile_verify_code/verify_code`,
    data,
    (response, body) => callback(body)
  );
};

export const fetchNewCodeVerify2 = (data, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/mobile_verify_code/user_send_mobile_verfiy_code`,
    data,
    (response, body) => callback(body)
  );
};

export const mobileVerified = (data, callback) => {
  formPost(
    `${PROTOCOL}//${WWWHost}/settings/mobile/verified`,
    data,
    (response, body) => callback(body)
  );
};

export const verfiyIdentidy = (type, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/settings/${type}/edit`,
    {},
    (response, body) => callback(body),
    "GET"
  );
};

export const updatePhone = (data, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/settings/mobile`,
    {
      "user[mobile]": data.mobile,
      "user[phone_verify_code]": data.code,
    },
    (response, body) => callback(body),
    "PATCH"
  );
};

export const fetchVerifyIdentidyCode = (value, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/send_verification_code`,
    {
      login_name: value,
    },
    (response, body) => callback(body)
  );
};

export const verifyIdentidy = (value, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/sudo`,
    {
      code: value,
    },
    (response, body) => callback(body)
  );
};

export const updateEmail = (email, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/settings/email`,
    {
      "user[email]": email,
    },
    (response, body) => callback(body),
    "PATCH"
  );
};

export const updatePassword = (data, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/settings/password`,
    data,
    (response, body) => callback(body),
    "PATCH"
  );
};

export const cancelBind = (type, callback) => {
  return formPost(
    `${PROTOCOL}//${WWWHost}/settings/authorizations/${type}`,
    {},
    (response, body) => callback(body),
    "DELETE"
  );
};

/**
 * 根据用户名获取验证码
 * @param {*} data login_name | _rucaptcha
 * @param {*} callback
 */
export const getCodeByAccount = (data, callback) => {
  console.log("发送验证码", data);
  return formPost(
    `${window.location.protocol}//${SUB_DOMAIN}.${TOP_DOMAIN}/send_verification_code`,
    data,
    (response, body) => callback(body)
  );
};

/**
 * 校验code
 * @param {*} data login_name | code
 * @param {*} callback
 */
export const validateCode = (data, callback) => {
  return formPost(
    `${window.location.protocol}//${SUB_DOMAIN}.${TOP_DOMAIN}/sudo`,
    data,
    (response, body) => callback(body)
  );
};

/**
 * 密码重置
 * @param {*} data login_name | password
 * @param {*} callback
 */
export const resetPassword = (data, callback) => {
  return formPost(
    `${window.location.protocol}//${SUB_DOMAIN}.${TOP_DOMAIN}/password_reset`,
    data,
    (response, body) => callback(body)
  );
};

export const checkCode = code => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/coupon_codes/check.json?code=${code}`
  ).then(JSON.parse);
};

export const exchangeCode = code => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/coupon_codes/exchange.json?code=${code}`
  ).then(JSON.parse);
};

export const fetchReducedPrice = (couponCode, productId) => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/users/use_code.json?code=${couponCode}&productId=${productId}`
  ).then(JSON.parse);
};

export const fetchRankings = () => {
  return getRequestDealWithAuth(`${API_ENDPOINT}/rating/rankings.json`).then(
    JSON.parse
  );
};

export const fetchAwardList = id => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/rating/ranking_tasks.json?ranking_id=${id}`
  ).then(JSON.parse);
};

export const fetchSelectionRateInfo = (id, rankId) => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/rating/ranking_tasks/${id}.json?ranking_form_id=${rankId}`
  ).then(JSON.parse);
};

export const submitSelectionRateForm = (id, data) => {
  return requestWrap(
    `${API_ENDPOINT}/rating/ranking_tasks/${id}.json`,
    {
      data: data,
    },
    "PATCH"
  );
};

export const fetchUserInfoApi = () => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/member_only_contents/member_info.json?time=${new Date().getTime()}`
  ).then(JSON.parse);
};

export const fetchMemberArticleApi = id => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/member_only_contents/${id}.json`
  ).then(JSON.parse);
};

export const fetchPcMemberArticleApi = id => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/pc/member_only_contents/${id}.json`
  ).then(JSON.parse);
};

export const fetchMemberListApi = params => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/member_only_contents.json?page=${params.page}&per=${params.per}`
  ).then(JSON.parse);
};

export const fetchPcMemberListApi = params => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/pc/member_only_contents.json?page=${params.page}&per=${params.per}`
  ).then(JSON.parse);
};

export const fetchReferenceListApi = params => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/member_only_contents/reference.json?page=${params.page}&per=${params.per}`
  ).then(JSON.parse);
};

export const fetchPcReferenceListApi = params => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/pc/member_only_contents/reference.json?page=${params.page}&per=${params.per}`
  ).then(JSON.parse);
};

export const fetchSearchListApi = (keyword, page, per) => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/member_only_contents/search.json?page=${page}&per=${per}&keyword=${keyword}`
  ).then(JSON.parse);
};

export const unlockMemberApi = data => {
  return requestWrap(
    `${API_ENDPOINT}/member_only_contents/${data.id}/unlocked.json`,
    data
  );
};

export const updateInfoApi = user => {
  return requestWrap(
    `${API_ENDPOINT}/member_only_contents/update_info.json`,
    user
  );
};

export const editInfoApi = user => {
  return requestWrap(`${API_ENDPOINT}/users/update_info.json`, user);
};

export const updateInfoXyApi = pro_member_license_version => {
  return requestWrap(
    `${API_ENDPOINT}/member_only_contents/pro_licensed_record.json`,
    {
      pro_member_license_version,
    }
  );
};

export const fetchMemberInfoApi = id => {
  const _id = id || "a69db391-c677-4417-8eb1-dc7c5e58dd64";
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/pro_orders/product.json?id=${_id}`
  ).then(JSON.parse);
};

export const fetchIntegralInfoApi = (
  id = "dc2a39bd-a9db-4a2b-9ce2-0e56592eb808"
) => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/pro_orders/product.json?id=${id}`
  ).then(JSON.parse);
};

export const fetchPayInfoApi = orderId => {
  return getRequestDealWithAuth(
    `${API_ENDPOINT}/pro_orders/get_wechat_qr.json?orderId=${orderId}`
  ).then(JSON.parse);
};

export const createPayOrderApi = proProductId => {
  return requestWrap(`${API_ENDPOINT}/pro_orders.json`, { proProductId });
};
