import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

import "./_authentication.scss";
import { Form, Input, message } from "antd";
import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import authStore from "../../stores/authStore";
import memberInboxStore from "../../stores/memberInboxStore";
import { mobileVerified } from "../../api/index";
import SyncedLogo from "../../styles/images/logo-synced-black.png";
import ProImg from "../../styles/images/pro.svg";

const Authentication: React.FC = () => {
  const [form] = Form.useForm();
  const { memberInfo, fetchMemberInfo } = useContext(authStore);
  const { onSendCode, time } = useContext(memberInboxStore);
  const [isComplete, setIsComplete] = useState(false);
  const id = window.sessionStorage.getItem("id");

  // const captchaRef = useRef(null);

  const onFieldsChange = () => {
    const values: any = form.getFieldsValue();
    const { mobile, phone_verify_code } = values;
    setIsComplete(
      !!mobile &&
        !!phone_verify_code &&
        form.getFieldsError().filter(({ errors }) => errors.length).length === 0
    );
  };

  const onFinish = async values => {
    if (isComplete) {
      setIsComplete(false);
      const params = {
        mobile_verify: {
          mobile: values.mobile,
          phone_verify_code: values.phone_verify_code,
        },
        // _rucaptcha: values._rucaptcha,
      };
      await mobileVerified(params, response => {
        if (response.statusCode === 200) {
          message.success("实名认证成功");
          setIsComplete(true);
          const code = window.sessionStorage.getItem("code") || "";
          window.location.replace(
            id ? `/inbox/${id}` : code ? `/inbox?code=${code}` : `/inbox`
          );
        } else {
          message.error(response.body.errors);
        }
      });
    }
  };

  useEffect(() => {
    fetchMemberInfo();
  }, []);

  // eslint-disable-next-line no-unused-vars
  let captcha;
  const getInstance = instance => {
    captcha = instance;
  };

  const sendDecorator = async () => {
    const mobile = form.getFieldValue("mobile");
    if (!mobile || !/^1[3456789]\d{9}$/.test(mobile)) {
      message.error("请输入正确的手机号码");
    } else {
      (document.querySelector("#captcha-button") as any).click();
    }
  };

  const captchaVerifyCallback = async captchaVerifyParam => {
    let result: any = {};
    let error = false;
    try {
      const values: any = form.getFieldsValue();
      const { mobile } = values;
      result = await onSendCode({ captchaVerifyParam, mobile });
    } catch (e) {
      error = e;
    }

    console.log("result:", result);

    if (error) {
      captcha.refresh();
      captcha.hide();
    } else {
      return {
        captchaResult: result.captchaResult, // 验证码验证是否通过，boolean类型，必选 result.captchaVerifyResult
        bizResult: result.bizResult, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      };
    }
  };

  // 验证通过后调用
  const onBizResultCallback = bizResult => {
    console.log("bizResult:", bizResult);
  };

  useEffect(() => {
    // 只用初始化一次验证码即可
    (window as any).initAliyunCaptcha({
      SceneId: "nepl2ukj", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: "mk2426", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: "#captcha-element", // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: "#captcha-button", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 360,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    });
    return () => {
      // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
      document.getElementById("aliyunCaptcha-mask")?.remove();
      document.getElementById("aliyunCaptcha-window-popup")?.remove();
    };
  }, []);

  return (
    <div className="member-inbox-authentication-layout">
      <Helmet>
        <title>绑定信息 ｜ {labelToChinese[PRO]}</title>
      </Helmet>
      <div className="authentication__header">
        {/* <Link to="/"> */}
        <img src={SyncedLogo} alt="Synced" />
        <img src={ProImg} />
        {/* </Link> */}
      </div>
      <div className="authentication__content">
        <p className="authentication__content__title">实名认证</p>
        <div className="authentication__content__desc">
          根据国家法律法规要求，绑定手机号获得更高级的安全保护。
        </div>
        {memberInfo && (
          <Form
            className="authentication__form"
            initialValues={memberInfo}
            autoComplete="off"
            form={form}
            onFieldsChange={onFieldsChange}
            onFinish={values => onFinish(values)}
          >
            <Form.Item
              rules={[
                { required: true, message: "请输入手机号" },
                {
                  pattern: /^1[3456789]\d{9}$/,
                  message: "请输入正确的手机号",
                },
              ]}
              label="手机号"
              name="mobile"
            >
              <Input placeholder="请输入手机号" />
            </Form.Item>
            {/* <div className="authentication__form-code">
              <Form.Item
                hasFeedback
                rules={[
                  { required: true, message: "请输入正确的验证码" },
                  { min: 5, message: "验证码是 5 位" },
                ]}
                name="_rucaptcha"
              >
                <Input autoComplete="off" placeholder="图形验证码" />
              </Form.Item>
              <img
                className="authentication__captcha"
                ref={captchaRef}
                src="/rucaptcha/"
                alt="图形验证码"
                onClick={e => updateCaptcha(captchaRef.current, e)}
              />
            </div> */}
            <div className="authentication__form-code">
              <Form.Item
                hasFeedback
                rules={[{ required: true, message: "请输入正确的验证码" }]}
                name="phone_verify_code"
              >
                <Input autoComplete="off" placeholder="验证码" />
              </Form.Item>
              <div
                className={`authentication__form-send-code ${time > 0 &&
                  "authentication__form-send-code--time"}`}
                onClick={() => {
                  if (time <= 0) {
                    sendDecorator();
                  }
                }}
              >
                {time > 0 ? `${time}s 后重新发送` : "发送验证码"}
              </div>
              <div id="captcha-button" />
              <div id="captcha-element" />
            </div>
            <div
              className={`authentication__form-btn ${!isComplete &&
                "authentication__form-btn--opacity"}`}
              onClick={() => form.submit()}
            >
              提交
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default observer(Authentication);
